const host = {
  authServer: 'https://idp-dev.unicloudgroup.com.vn/realms/realm_ksfinance',
  apiServer: 'https://dev.api.unicloudgroup.com.vn',
  apConfigServer: 'https://dev.api.ksfadmin.unicloudgroup.com.vn',
  apiKSFServer: 'https://dev.api.invest.plus.unicloudgroup.com.vn',
  apiCoreServer: 'https://dev.api.kscore.unicloudgroup.com.vn',
  uploadServer: 'https://data.sunshinegroup.vn/api/v1/FileHandler',
  cloudFunction: 'https://asia-northeast1-sunshine-app-production.cloudfunctions.net'
};

const authenSettings = {
  authority: host.authServer,
  client_id: 'web_ksf_ksiplus_dev',
  redirect_uri: 'https://dev-web-invest-plus.unicloudgroup.com.vn/auth-callback',
  post_logout_redirect_uri: 'https://dev-web-invest-plus.unicloudgroup.com.vn',
  response_type: 'id_token token',
  scope: 'openid profile',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: 'https://dev-web-invest-plus.unicloudgroup.com.vn/silent-refresh.html'

};


const firebase = {
  apiKey: 'AIzaSyAb3orVc8nnGT0L2JgbdzXrRND393mFiFU',
  authDomain: 'sunshine-app-production.firebaseapp.com',
  databaseURL: 'https://sunshine-app-production.firebaseio.com',
  projectId: 'sunshine-app-production',
  storageBucket: 'sunshine-app-production.appspot.com'
  // apiKey: 'AIzaSyAczqJoNnTDPPLktoPtQ694IH38sR8wX6w',
  // authDomain: 'sunshine-super-app.firebaseapp.com',
  // databaseURL: 'https://sunshine-super-app.firebaseio.com',
  // projectId: 'sunshine-super-app',
  // storageBucket: 'sunshine-super-app.appspot.com',
  // messagingSenderId: '504497996884',
  // appId: '1:504497996884:web:e07732b704e759529819c1',
};

export const environment = {
  production: true,
  apiBase: host.apiServer,
  apiKSFBase: host.apiKSFServer,
  apConfigBase: host.apConfigServer,
  apiCoreBase: host.apiCoreServer,
  cloudFunctionServer: host.cloudFunction,
  authenSettings: authenSettings,
  firebase
};
