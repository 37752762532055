import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings, } from 'oidc-client';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FirebaseAuthService } from './firebase-auth.service';
@Injectable()
export class AuthService {

  private manager: UserManager = new UserManager(environment.authenSettings);
  private user: any = null;
  private account: any = null;
  constructor(private http: HttpClient, private router: Router, private firebaseAuthService: FirebaseAuthService,) {
    this.manager.getUser().then(user => {
      this.user = user;
    });
  }

  isLoggedIn(): Promise<boolean> {
    localStorage.removeItem('menuItems');
    if (this.user != null) {
      return new Promise((resolve, reject) => resolve(!this.user.expired));
    }

    return this.manager.getUser().then(user => {
      this.user = user;
      return user != null && !user.expired;
    });
  }

  getClaims(): any {
    return this.user.profile;
  }

  getPermissions(): any {
    return this.account;
  }

  getAuthorizationHeaderValue(): string {
    return `Bearer ${this.user.access_token}`;
  }

  getAccessTokenValue(): string {
    return this.user.access_token;
  }

  getUserName(): string {
    return this.user.profile.preferred_username;
  }

  isExpired(): boolean {
    return this.user.expired
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  signout(): Promise<void> {
    localStorage.removeItem('menuItems');
    localStorage.removeItem('tokenFb');
    localStorage.removeItem('account');
    return this.manager.signoutRedirect();
  }

  completeAuthentication(): Promise<void> {
    localStorage.setItem('projectCd', '01');
    return this.manager.signinRedirectCallback()
      .then(user => { this.user = user; })
      .then(_ => this.getEmpDetail());
  }

  getUserImage(): string {
    return localStorage.getItem('avatarUrl') || '';
  }

  async getEmpDetail() {

    // if (localStorage.getItem("employeeId") === null || localStorage.getItem("employeeId") === 'undefined') {
    //   const headers = new HttpHeaders({ Authorization: this.getAuthorizationHeaderValue() });
    //   return this.http.get(environment.apiBase + '/api/v2/employee/GetEmployee?employeeId=', { headers }).toPromise()
    //     .then((emp: any) => {
    //       if (emp && emp.data) {
    //         localStorage.setItem('avatarUrl', emp.data.avatarUrl);
    //         localStorage.setItem('employeeId', emp.data.employeeId);
    //       }
    //     });
    // }

    const token = this.getAccessTokenValue();
    if (!this.firebaseAuthService.authenticated) {
      const customToken = await this.getCustomToken(token);
      if (customToken) {
        this.firebaseAuthService.customLogin(customToken);
      }
    }
  }

  getCustomToken(token: string): Promise<any> {
    const url = `${environment.cloudFunctionServer}/getCustomTokenKC`;
    return this.http.post(url, {
      data: {
        access_token: token
      }
    }).pipe(
      map((response: any) => response.result)
    ).toPromise();

  }

  getAccount() {
    if(this.user) {
     const options = {
       headers: new HttpHeaders({
         Authorization: `${this.user.token_type} ${this.user.access_token}`
       })
     };

    //  this.getAccountLogin(options).subscribe(result => {
    //    if (result) {
    //      this.account = result;
    //      localStorage.setItem('account', JSON.stringify(this.account));
    //    }
    //  })
    }
 }


  getWorkingProject() {
    return localStorage.getItem('projectCd');
  }
}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: 'https://api.sunshinegroup.vn:5000',
    client_id: 'web_s_service_dev',
    redirect_uri: 'http://localhost:4200/auth-callback',
    post_logout_redirect_uri: 'http://localhost:4200',
    response_type: 'id_token token',
    scope: 'open_id profile api_sre',
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    silent_redirect_uri: 'http://localhost:4200/silent-refresh.html'
  };
}
